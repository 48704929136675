<template>
  <div class="help">
    {{$t('auth.If you have any questions, please contact support')}}
    <TLink
      :is-nuxt-link="false"
      :is-underlined="false"
      :route="`mailto:${$options.emailLink}`"
    >
      {{$options.emailLink}}
    </TLink>
  </div>
</template>

<script>
import { ALLTRUCKS_SUPPORT_EMAIL } from '~/assets/js/constants';

export default {
  name: 'HelpCenter',

  emailLink: ALLTRUCKS_SUPPORT_EMAIL,
};
</script>

<style lang='scss'>
@import '~/assets/scss/_variables';

.help {
  color: $steel-grey;
}
</style>
