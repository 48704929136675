<template>
  <div class="auth-template">
    <AuthLocaleSelector class="auth-template__auth-locale-selector" />
    <THeading
      class="auth-template__heading"
      :class="isHeadingMargin4 ? 'mb-4' : 'mb-2'"
    >
      <slot name="heading" />
    </THeading>
    <template>
      <slot name="heading-follower" />
    </template>
    <template>
      <slot name="form" />
    </template>
    <HelpCenter />
  </div>
</template>

<script>
import HelpCenter from './HelpCenter';
import AuthLocaleSelector from './AuthLocaleSelector';

export default {
  name: 'AuthTemplate',

  components: {
    HelpCenter,
    AuthLocaleSelector,
  },

  props: {
    isHeadingMargin4: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
@import '~/assets/scss/_variables';
@import '~/assets/scss/_mixins';

.auth-template {
  position: relative;
  width: 495px;
  padding: 50px 78px 60px;
  background-color: #fff;
  box-shadow: $shadow-black;

  &__auth-locale-selector {
    position: absolute;
    top: 15px;
    right: 22px;
  }

  &__password-btn {
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
  }

  &__major-link{
    font-size: 1.15rem;
    line-height: 1.25;
  }

  .t-heading {
    white-space: normal;
  }

  .t-field__field {
    font-size: 1.3rem;
    line-height: 1.3;
  }

  .t-field__field,
  .t-button:not(.auth-template__password-btn) {
    height: $auth-input-height;
    width: 100%;
  }

  .t-button {
    padding-top: 0;
    padding-bottom: 0;
    @include centerFlexY;
    text-transform: uppercase;
  }
}
</style>
